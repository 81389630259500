import * as React from 'react';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useState } from 'react';
import { useEffect } from 'react';

function App() {
  const [locations, setLocations] = useState([]);

  const getLocations = async () => {
    const res = await fetch("https://api.cruiz.ai/api/locations");
    const result = await res.json();

    setLocations(result);
  }

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <>
      <div style={{ width: '100vw', height: '100vh', background: 'black' }}>
        {locations.length > 0 && <Map
          mapboxAccessToken="pk.eyJ1Ijoid2l0aHlvdTc0NiIsImEiOiJjbHVkbWtsN3Uxa3dtMmtxZWgxemVzcGs1In0.WCo_135v6YlCmPOPytmMew"
          initialViewState={{
            longitude: -122.4,
            latitude: 37.8,
            zoom: 2.5
          }}
          style={{ width: '100%', height: '100%' }}
          mapStyle="mapbox://styles/mapbox/dark-v11"
        >
          {locations.map((loc, index) => (
            <Marker key={index} longitude={loc.longitude} latitude={loc.latitude}>
              <svg height="10" width="10" viewBox="0 0 10 10">
                <radialGradient id={`waterinkGradient-${index}`} cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                  <stop offset="0%" style={{ stopColor: '#00ff80', stopOpacity: 1 }} />
                </radialGradient>
                <circle cx="5" cy="5" r="5" fill={`url(#waterinkGradient-${index})`} />
              </svg>
            </Marker>
          ))}
        </Map>
        }
      </div>
      <div className='overlay'>
        <img src="logo.png" alt="logo" />
        <p>Signups: {locations.length > 0 ? locations.length : 'loading...'}</p>
      </div>
    </>
  );
}

export default App;
